import { GoListOrdered } from 'react-icons/go';
import { useContext } from 'react';
import CourseContext from '../context/CourseContext';

function Navbar() {
	const { roundStarted, handleLeaderboardChange, currentHole } =
		useContext(CourseContext);
	return (
		roundStarted && (
			<div className='navbar bg-base-300 justify-end'>
				<label
					htmlFor='scoreboard-drawer'
					className='btn btn-ghost normal-case text-xl'
					onClick={() => handleLeaderboardChange(currentHole)}
				>
					<GoListOrdered />
				</label>
			</div>
		)
	);
}

export default Navbar;

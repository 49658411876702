import { FaUserPlus, FaInfoCircle } from 'react-icons/fa';
import { useContext, useState } from 'react';
import CourseContext from '../context/CourseContext';

function AddPlayerForm() {
	const [text, setText] = useState('');
	const [message, setMessage] = useState('');
	const [btnDisabled, setBtnDisabled] = useState(true);
	const { addPlayer, players, startRound, roundStarted } =
		useContext(CourseContext);

	const handleTextChange = ({ target: { value } }) => {
		if (value === '') {
			setBtnDisabled(true);
			setMessage(null);
		} else if (/[^a-zA-Z ]/g.test(value.trim())) {
			setMessage('Name cannot contain special characters');
			setBtnDisabled(true);
		} else {
			setMessage(null);
			setBtnDisabled(false);
		}
		setText(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!/[^a-zA-Z ]/g.test(text.trim())) {
			const newPlayer = {
				id: Math.floor(Math.random() * 1000),
				name: text,
				score: 0,
			};

			addPlayer(newPlayer);

			setBtnDisabled(true);
			setText('');
		}
	};

	return (
		!roundStarted && (
			<>
				<form
					className='mb-4 p-4'
					onSubmit={handleSubmit}
				>
					<div className='form-control w-full flex flex-row'>
						<input
							onChange={handleTextChange}
							type='text'
							placeholder='John Doe'
							value={text}
							className='input input-bordered w-full max-w-xs mr-4'
						/>
						<button
							type='submit'
							disabled={btnDisabled}
							className='btn btn-square btn-success'
						>
							<FaUserPlus />
						</button>
					</div>
					{message && (
						<div className='alert alert-error mt-4 flex flex-row justify-start'>
							<FaInfoCircle />
							{message}
						</div>
					)}
				</form>
				<div className='px-4 py-4'>
					<button
						onClick={() => startRound()}
						disabled={!players.length}
						className='btn btn-accent btn-block'
					>
						Start Round
					</button>
				</div>
			</>
		)
	);
}

export default AddPlayerForm;

import { FaPlus, FaMinus } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import CourseContext from '../context/CourseContext';

function PlayerCard({ id, name, holePar }) {
	const {
		currentHole,
		scoreboard,
		courseHoleDetails,
		handleScoreUpdate,
		plusStroke,
		minusStroke,
	} = useContext(CourseContext);

	const [totalScore, setTotalScore] = useState(0);
	const [prettyScore, setPrettyScore] = useState(0);
	const [holeDiff, setHoleDiff] = useState(0);
	const [holeScore, setHoleScore] = useState(0);

	const configureHoleScore = () => {
		// current score
		const scores = scoreboard.find(
			(hole) => hole.holeNumber === currentHole.id
		).scores;
		const currentHoleScore = scores.find(
			(player) => player.playerId === id
		).score;
		setHoleScore(currentHoleScore);

		// total score
		let totalScore = 0;
		scoreboard.forEach((hole) => {
			const playersScore = hole.scores.find((player) => player.playerId === id);
			totalScore = totalScore + playersScore.score;
		});
		setTotalScore(totalScore);

		// score diff
		let totalHoleDiff = 0;
		scoreboard.forEach((hole) => {
			const holeHasScore = hole.scores.filter(
				(score) => score.score && score.playerId === id
			);
			if (holeHasScore.length) {
				const thisHolesPar = courseHoleDetails.find(
					(courseHole) => courseHole.id === hole.holeNumber
				).par;
				const currentHoleDiff = holeHasScore[0].score - thisHolesPar;
				totalHoleDiff = totalHoleDiff + currentHoleDiff;
				setHoleDiff(totalHoleDiff);
			}
		});
		const prettyDisplay =
			totalHoleDiff > 0 ? '+' + totalHoleDiff : totalHoleDiff;
		setPrettyScore(totalHoleDiff !== 0 ? prettyDisplay : 'E');
	};

	const setHoleScoreDefault = () => {
		const scores = scoreboard.find(
			(hole) => hole.holeNumber === currentHole.id
		).scores;
		let currentHoleScore = scores.find(
			(player) => player.playerId === id
		).score;
		setHoleScore(currentHoleScore);
	};

	useEffect(() => {
		configureHoleScore();
	}, [scoreboard]);

	useEffect(() => {
		setHoleScoreDefault();
	}, [currentHole]);

	return (
		<>
			<div className='card w-auto bg-base-100 border border-slate-700 rounded-none'>
				<div className='card-body p-4 flex flex-row'>
					<div className='basis-3/4'>
						<h3 className='card-title font-bold flex flex-row items-center'>
							<BsFillPersonFill className='mr-1' />
							{name}
						</h3>
						<p className='text-small'>
							{prettyScore}
							&nbsp;({totalScore})
						</p>
					</div>
					<div className='basis-1/4 flex flex-row items-center'>
						<button
							onClick={() =>
								handleScoreUpdate(
									id,
									currentHole.id,
									minusStroke(holeScore, currentHole.par)
								)
							}
							className='btn btn-circle btn-base-300 text-xl font-extrabold leading-1'
						>
							<FaMinus />
						</button>
						<span className='text-4xl font-semibold px-4'>
							{holeScore !== null ? holeScore : ''}
						</span>
						<button
							onClick={() =>
								handleScoreUpdate(
									id,
									currentHole.id,
									plusStroke(holeScore, currentHole.par)
								)
							}
							className='btn btn-circle btn-accent text-xl font-extrabold leading-1'
						>
							<FaPlus />
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

// PropTypes.PlayerCard = {
// 	name: PropTypes.string,
// 	totalScore: PropTypes.number,
// };

export default PlayerCard;

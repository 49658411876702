import React from 'react';
import Leaderboard from './Leaderboard';
// import { FaCaretLeft } from 'react-icons/fa';

function Drawer() {
	return (
		<div className='drawer-side'>
			<label
				htmlFor='scoreboard-drawer'
				className='drawer-overlay'
			></label>
			<div className='menu p-0 w-80 bg-base-300 text-base-content flex flex-col justify-between'>
				<div className='flex flex-col justify-start'>
					<table className='table w-full'>
						<thead>
							<tr>
								<th className='text-center'>Pos</th>
								<th
									colSpan='2'
									className='text-left'
								>
									Name
								</th>
								<th className='text-center'>Score</th>
								<th className='text-center'>Thru</th>
							</tr>
						</thead>
						<tbody>
							<Leaderboard />
						</tbody>
					</table>
				</div>
				<div className='p-4'>
					<label
						htmlFor='scoreboard-drawer'
						className='btn btn-accent normal-case text-xl flex flex-row'
					>
						Close
					</label>
				</div>
			</div>
		</div>
	);
}

export default Drawer;

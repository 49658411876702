import PlayerList from './components/PlayerList';
import CourseNav from './components/CourseNav';
import HoleDetails from './components/HoleDetails';
import AddPlayerForm from './components/AddPlayerForm';
import Drawer from './components/Drawer';
import Navbar from './components/Navbar';
import { CourseProvider } from './context/CourseContext';

function App() {
	return (
		<CourseProvider>
			<>
				<div className='drawer drawer-end'>
					<input
						id='scoreboard-drawer'
						type='checkbox'
						className='drawer-toggle'
					/>
					<div className='drawer-content'>
						<Navbar />
						<AddPlayerForm />
						<HoleDetails />
						<PlayerList />
						<CourseNav />
					</div>
					<Drawer />
				</div>
			</>
		</CourseProvider>
	);
}

export default App;

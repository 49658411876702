import { createContext, useState, useEffect } from 'react';

const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
	const [currentHole, setCurrentHole] = useState({});

	const [players, setPlayers] = useState([]);

	const [leaderboard, updateLeaderboard] = useState([]);

	const [scoreboard, updateScoreboard] = useState([]);

	const [courseHoleDetails, setCourseHoleDetails] = useState([
		{
			id: 1,
			par: 4,
			distance: 392,
		},
		{
			id: 2,
			par: 4,
			distance: 418,
		},
		{
			id: 3,
			par: 3,
			distance: 310,
		},
		{
			id: 4,
			par: 3,
			distance: 188,
		},
		{
			id: 5,
			par: 3,
			distance: 202,
		},
		{
			id: 6,
			par: 4,
			distance: 418,
		},
		{
			id: 7,
			par: 3,
			distance: 245,
		},
		{
			id: 8,
			par: 3,
			distance: 305,
		},
		{
			id: 9,
			par: 3,
			distance: 190,
		},
	]);

	const [fakeScoreboard, setFakeScorecard] = useState([
		{
			holeNumber: 1,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 3, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 3, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 3, prettyScore: -1 },
				{ playerId: 4, name: 'Simon Lizotte', score: 4, prettyScore: 0 },
				{ playerId: 5, name: 'Chris Dickerson', score: 3, prettyScore: -1 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: -1 },
				{ playerId: 7, name: 'Gannon Buhr', score: 4, prettyScore: 0 },
				{ playerId: 8, name: 'Isaac Robinson', score: 4, prettyScore: 0 },
			],
		},
		{
			holeNumber: 2,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 3, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 3, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 4, prettyScore: 0 },
				{ playerId: 4, name: 'Simon Lizotte', score: 3, prettyScore: -1 },
				{ playerId: 5, name: 'Chris Dickerson', score: 3, prettyScore: -1 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: -1 },
				{ playerId: 7, name: 'Gannon Buhr', score: 4, prettyScore: 0 },
				{ playerId: 8, name: 'Isaac Robinson', score: 4, prettyScore: 0 },
			],
		},
		{
			holeNumber: 3,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 2, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 2, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 2, prettyScore: -1 },
				{ playerId: 4, name: 'Simon Lizotte', score: 3, prettyScore: 0 },
				{ playerId: 5, name: 'Chris Dickerson', score: 3, prettyScore: 0 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: 0 },
				{ playerId: 7, name: 'Gannon Buhr', score: 2, prettyScore: -1 },
				{ playerId: 8, name: 'Isaac Robinson', score: 3, prettyScore: 0 },
			],
		},
		{
			holeNumber: 4,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 2, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 2, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 2, prettyScore: -1 },
				{ playerId: 4, name: 'Simon Lizotte', score: 3, prettyScore: 0 },
				{ playerId: 5, name: 'Chris Dickerson', score: 2, prettyScore: -1 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: 0 },
				{ playerId: 7, name: 'Gannon Buhr', score: 2, prettyScore: -1 },
				{ playerId: 8, name: 'Isaac Robinson', score: 2, prettyScore: -1 },
			],
		},
		{
			holeNumber: 5,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 3, prettyScore: 0 },
				{ playerId: 2, name: 'Eagle McMahon', score: 3, prettyScore: 0 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 2, prettyScore: -1 },
				{ playerId: 4, name: 'Simon Lizotte', score: 2, prettyScore: -1 },
				{ playerId: 5, name: 'Chris Dickerson', score: 3, prettyScore: 0 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 2, prettyScore: -1 },
				{ playerId: 7, name: 'Gannon Buhr', score: 2, prettyScore: -1 },
				{ playerId: 8, name: 'Isaac Robinson', score: 4, prettyScore: 1 },
			],
		},
		{
			holeNumber: 6,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 3, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 3, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 3, prettyScore: -1 },
				{ playerId: 4, name: 'Simon Lizotte', score: 4, prettyScore: 0 },
				{ playerId: 5, name: 'Chris Dickerson', score: 3, prettyScore: -1 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: -1 },
				{ playerId: 7, name: 'Gannon Buhr', score: 4, prettyScore: 0 },
				{ playerId: 8, name: 'Isaac Robinson', score: 3, prettyScore: -1 },
			],
		},
		{
			holeNumber: 7,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 2, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 2, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 2, prettyScore: -1 },
				{ playerId: 4, name: 'Simon Lizotte', score: 2, prettyScore: -1 },
				{ playerId: 5, name: 'Chris Dickerson', score: 2, prettyScore: -1 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 2, prettyScore: -1 },
				{ playerId: 7, name: 'Gannon Buhr', score: 2, prettyScore: -1 },
				{ playerId: 8, name: 'Isaac Robinson', score: 3, prettyScore: -1 },
			],
		},
		{
			holeNumber: 8,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 2, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 3, prettyScore: 0 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 3, prettyScore: 0 },
				{ playerId: 4, name: 'Simon Lizotte', score: 2, prettyScore: -1 },
				{ playerId: 5, name: 'Chris Dickerson', score: 3, prettyScore: 0 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: 0 },
				{ playerId: 7, name: 'Gannon Buhr', score: 3, prettyScore: 0 },
				{ playerId: 8, name: 'Isaac Robinson', score: 3, prettyScore: 0 },
			],
		},
		{
			holeNumber: 9,
			scores: [
				{ playerId: 1, name: 'Paul McBeth', score: 2, prettyScore: -1 },
				{ playerId: 2, name: 'Eagle McMahon', score: 2, prettyScore: -1 },
				{ playerId: 3, name: 'Ricky Wysocki', score: 3, prettyScore: 0 },
				{ playerId: 4, name: 'Simon Lizotte', score: 2, prettyScore: -1 },
				{ playerId: 5, name: 'Chris Dickerson', score: 2, prettyScore: -1 },
				{ playerId: 6, name: 'Calvin Heimburg', score: 3, prettyScore: 0 },
				{ playerId: 7, name: 'Gannon Buhr', score: 2, prettyScore: -1 },
				{ playerId: 8, name: 'Isaac Robinson', score: 2, prettyScore: -1 },
			],
		},
	]);

	const [roundStarted, setRoundStart] = useState(false);

	const startRound = () => setRoundStart(true);

	const handleHoleChange = (id) => {
		const hole = courseHoleDetails.find((hole) => hole.id === id);
		setCurrentHole(hole);
	};

	const minusStroke = (stroke, par) => {
		let str = stroke;
		if (!stroke) {
			str = par;
		}
		return str > 1 ? str - 1 : 1;
	};

	const plusStroke = (stroke, par) => {
		let str = stroke;
		if (!stroke) {
			str = par - 1;
		}
		return str + 1;
	};

	const handleScoreUpdate = (playerId, affectedHole, newScore) => {
		const scoreToUpdate = {
			playerId: playerId,
			name: players.find((player) => player.id === playerId).name,
			score: newScore,
			prettyScore: newScore - currentHole.par,
		};
		updateScoreboard(
			scoreboard.map((hole) =>
				hole.holeNumber === affectedHole
					? {
							...hole,
							scores: hole.scores.map((scores) =>
								scores.playerId === playerId
									? {
											...scores,
											...scoreToUpdate,
									  }
									: scores
							),
					  }
					: hole
			)
		);
	};

	const setUpScoreBoard = () => {
		const scores = returnPlayers();
		updateScoreboard(
			courseHoleDetails.map(function (element, index, array) {
				return {
					holeNumber: element.id,
					scores: scores,
				};
			})
		);
	};

	const handleLeaderboardChange = (currentHole) => {
		const newLeaderboard = [];

		scoreboard.forEach((hole) => {
			hole.scores
				.filter((person) => {
					return person.score;
				})
				.forEach((person) => {
					const existing = newLeaderboard.find(
						(existingP) => existingP.id === person.playerId
					);
					if (existing) {
						existing.score = existing.score + person.prettyScore;
					} else {
						newLeaderboard.push({
							id: person.playerId,
							name: person.name,
							score: person.prettyScore,
							hole: currentHole.id <= 8 ? currentHole.id - 1 : 'F',
						});
					}
				});
		});

		fakeScoreboard.forEach((hole) => {
			hole.scores
				.filter((person) => {
					return hole.holeNumber <= currentHole.id;
				})
				.forEach((person, index) => {
					const holeNumber = index >= 4 ? currentHole.id + 1 : currentHole.id;
					const existing = newLeaderboard.find(
						(existingP) => existingP.id === person.playerId
					);
					if (existing) {
						existing.score = existing.score + person.prettyScore;
					} else {
						newLeaderboard.push({
							id: person.playerId,
							name: person.name,
							score: person.prettyScore,
							hole: holeNumber <= 8 ? holeNumber : 'F',
							fake: true,
						});
					}
				});
		});

		newLeaderboard.sort((a, b) => a.score - b.score);

		let pos = 1;
		newLeaderboard.forEach((person, index) => {
			if (index >= 1 && person.score > newLeaderboard[index - 1].score) {
				pos++;
			}

			if (
				(newLeaderboard[index - 1] &&
					person.score === newLeaderboard[index - 1].score) ||
				(newLeaderboard[index + 1] &&
					person.score === newLeaderboard[index + 1].score)
			) {
				const t = 'T';
				person.position = t.concat('', pos);
			} else {
				person.position = pos;
			}
		});

		updateLeaderboard(newLeaderboard);
	};

	const returnPlayers = () => {
		const scores = [];
		players.forEach((player) => {
			scores.push({ playerId: player.id, name: player.name, score: null });
		});
		return scores;
	};

	const addPlayer = (newPlayer) => {
		setPlayers([newPlayer, ...players]);
		updateLeaderboard([newPlayer, ...players]);
	};

	useEffect(() => {
		handleHoleChange(1);
		setUpScoreBoard();
	}, [players]);

	return (
		<CourseContext.Provider
			value={{
				currentHole,
				courseHoleDetails,
				players,
				leaderboard,
				scoreboard,
				roundStarted,
				handleHoleChange,
				handleScoreUpdate,
				handleLeaderboardChange,
				plusStroke,
				minusStroke,
				addPlayer,
				startRound,
			}}
		>
			{children}
		</CourseContext.Provider>
	);
};

export default CourseContext;

import { useContext } from 'react';
import CourseContext from '../context/CourseContext';

function CourseNav() {
	const { currentHole, courseHoleDetails, handleHoleChange, roundStarted } =
		useContext(CourseContext);

	return (
		roundStarted && (
			<>
				<div className='btm-nav btm-nav-lg font-bold'>
					{courseHoleDetails.map((hole) => (
						<button
							key={hole.id}
							className={`text-accent flex justify-center items-center ${
								currentHole.id === hole.id ? 'active bg-base-200' : ''
							}`}
							onClick={() => handleHoleChange(hole.id)}
						>
							{hole.id}
						</button>
					))}
				</div>
			</>
		)
	);
}

export default CourseNav;

import PlayerCard from './PlayerCard';
import { useContext, useEffect, useState } from 'react';
import CourseContext from '../context/CourseContext';
import { BsFillPersonPlusFill } from 'react-icons/bs';

function PlayerList({ roundStart }) {
	const { players, currentHole, roundStarted } = useContext(CourseContext);

	const [par, setPar] = useState(0);

	useEffect(() => {
		setPar(currentHole.par);
	}, [currentHole]);

	return (
		<div className='mb-auto'>
			{!roundStarted && (
				<ul className='px-4'>
					{players.map((player) => (
						<li
							key={player.id}
							className='text-3xl flex flex-row items-center py-2'
						>
							<BsFillPersonPlusFill className='mr-4' />
							<strong>{player.name}</strong>
						</li>
					))}
				</ul>
			)}

			{roundStarted &&
				players.map((player) => (
					<PlayerCard
						key={player.id}
						id={player.id}
						name={player.name}
						holePar={par}
					/>
				))}
		</div>
	);
}

export default PlayerList;

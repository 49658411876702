import { useContext, useEffect, useState } from 'react';
import CourseContext from '../context/CourseContext';
import { TbDiscGolf } from 'react-icons/tb';

function HoleDetails() {
	const { currentHole, roundStarted } = useContext(CourseContext);

	return (
		roundStarted && (
			<div className='p-2 flex flex-row justify-between text-sm font-bold text-slate-400 border border-slate-700 bg-slate-800'>
				<div className='flex flex-row items-center'>
					<TbDiscGolf className='mr-1' />
					Hole {currentHole.id}:{' '}
					<span className='ml-1'>{currentHole.distance} ft</span>
				</div>
				<div>Par: {currentHole.par}</div>
			</div>
		)
	);
}

export default HoleDetails;

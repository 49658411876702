import { useContext, useState, useEffect } from 'react';
import CourseContext from '../context/CourseContext';
import LeaderboardCard from './LeaderboardCard';

function Leaderboard() {
	const { leaderboard } = useContext(CourseContext);
	return leaderboard.map((player, index) => (
		<LeaderboardCard
			fake={player.fake}
			key={player.id}
			name={player.name}
			position={player.position}
			score={player.score}
			hole={player.hole}
		/>
	));
}

export default Leaderboard;

function LeaderboardCard({ name, score, position, hole, fake }) {
	let prettyScore = score > 0 ? '+' + score : score;
	prettyScore = score !== 0 ? prettyScore : 'E';

	return (
		<tr className={`text-sm ${fake ? 'bg-neutral-focus' : 'bg-neutral'}`}>
			<th className='bg-transparent text-center'>{position}</th>
			<td
				colSpan='2'
				className='bg-transparent text-left'
			>
				{name}
			</td>
			<td className='bg-transparent text-center'>{prettyScore}</td>
			<td className='bg-transparent text-center'>{hole}</td>
		</tr>
	);
}

export default LeaderboardCard;
